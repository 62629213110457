.jobForm-container {
  width: 100%;
  height: 100%;
  padding: 5em 0em;
  box-sizing: border-box;
}
.form-text{
    border:none;
    color: rgba(0, 0, 0, 0.85);
    background-color: transparent;
}
.ant-input-number-disabled .ant-input-number-input {
    cursor: default !important;
}
.sub-form-item{
    display: inline-block;
    width: 200px;
    margin-bottom: 0px;
}