.hidden-elm {
    display: none;
}
.text-btn{
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
  }
.form-text{
    border:none;
    color: rgba(0, 0, 0, 0.85);
    background-color: transparent;
}
.ant-input-number-disabled .ant-input-number-input {
    cursor: default !important;
}
.sub-form-item{
    display: inline-block;
    width: 200px;
    margin-bottom: 0px;
}