@import '../node_modules/antd/dist/antd.css';
@import "../node_modules/react-vis/dist/style.css";

html {
  max-height: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  max-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  height: 100%;
  max-height: 100%;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  margin-left: 2px;
}

::-webkit-scrollbar-track {
  background: #FAFAFB;
}

::-webkit-scrollbar-thumb {
  background: #CCCCCC; 
}

::-webkit-scrollbar-thumb:hover {
  background: #B3B3B3;
}