#landing-container {
    width:100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column wrap;
    padding-bottom: 5em;
}

#landing-container .ant-descriptions-item-label {
    padding: 1em 2em;
}

#landing-container .ant-descriptions-item-content {
    padding: 1em 2em;
}

amplify-authenticator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

@media only screen and (max-width: 768px) {
    #landing-container .ant-row {
        flex-direction: column-reverse;
    }
    .login-container {
        position: relative;
        width: 100%;
    }
}

@media only screen and (min-width: 768px) {
    #landing-container {
        padding: 2em;
        flex-flow: row wrap;
        justify-content: space-between;
    }
    .login-container {
        position: fixed;
        width: 50%;
    }
}
